<template>
  <div class="page-auth">
    <div class="page-auth__preview">
      <img src="@img/auth-preview.png" alt="">
    </div>
    <div class="page-auth__side">
      <router-view />
    </div>
  </div>
</template>

<script>

export default {

};
</script>

<style lang="scss" scoped>

// page-auth
.page-auth {
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: var(--bg-prime);
  .page-auth__preview {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    img {
      max-width: 90%;
      height: auto;
    }
  }
  .page-auth__side {
    display: flex;
    align-items: center;
    width: 50%;
    height: 100%;
    padding: 30px 76px;
  }
}

</style>
